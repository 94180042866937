import React from "react";
import projects from "../Data/projects";
import ProjectCard from "./Common/ProjectCard";

export default function ProjectsSection() {
  return (
    <section
      className="w-full max-w-screen-xl p-4 mx-auto text-white"
      style={{ scrollMarginTop: "60px" }}
      id="portfolio"
    >
      <h2 data-aos="slide-left" className="m-4 text-2xl font-bold">
        Projects
      </h2>
      <hr data-aos="fade-left" className="border-b border-lime-300" />
      <div className="flex flex-wrap m-8 justify-evenly">
        {projects.map((proj) => (
          <ProjectCard proj={proj} />
        ))}
      </div>
    </section>
  );
}

import React from 'react'

export default function Footer() {
  return (
    <div className='h-20 bg-zinc-800'>
      <div className='flex justify-between max-w-screen-xl px-12 py-4 mx-auto text-sm text-white'>
        <div/>
        <p>&copy; Themis Papathemistocleous</p>
        <div>
        <a href="#top">Scroll-top</a>
        </div>
      </div>
    </div>
  )
}

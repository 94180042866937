import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { useWindowDim } from "../../Hooks/useWindowDim";

export default function ResponsiveNav({ convertWidth, children }) {
  const windowDim = useWindowDim();
  const [expanded, setExpanded] = useState(false);
  if (windowDim.width < convertWidth)
    return (
      <div className="relative flex items-center justify-end w-full h-full text-white">
        <button
          className=""
          type="button"
          onClick={() => setExpanded((v) => !v)}
        >
          <FaBars size="2rem" />
        </button>
        <div
          className="fixed left-0 right-0 h-auto overflow-hidden transition duration-300 origin-top top-16 bg-zinc-800"
          style={{ transform: expanded ? "scaleY(1)" : "scaleY(0)" }}
        >
          {children({ dropdown: true, closeMenu: () => setExpanded(false) })}
        </div>
      </div>
    );
  return children({ dropdown: false, closeMenu: () => setExpanded(false) });
}

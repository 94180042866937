import { useEffect, useRef } from "react";

export default function usePushTransition() {
  let ref = useRef();
  function onHover(ev) {
    if(ref.current && ref.current.childNodes) {
      let before = true;
      ref.current.childNodes.forEach((child) => {
        if(child.matches(":hover")) {
          child.style = "transform: scale(130%);";
          before = false;
          return;
        }
        child.style = `transform: translateX(${before ? "-" : ""}10px)`;
      })
    }
  }
  useEffect(() => {
    if (!ref || !ref.current) return
    let elem = ref.current;
    function removeStyle(ev) {
      ev.target.childNodes.forEach((child) => {
        child.style = "";
      })
    }
    elem.addEventListener("mouseleave", removeStyle);
    return () => {
      elem.removeEventListener("mouseleave", removeStyle);
    }
  }, [ref])
  return [ref, onHover];
}
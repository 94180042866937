import React from "react";
import { FaLinkedin, FaGithub, FaEnvelope } from "react-icons/fa";

export default function ContactSection() {
  return (
    <div
      className="w-full max-w-screen-xl p-4 mx-auto text-white"
      style={{ scrollMarginTop: "60px" }}
      id="contact"
    >
      <h2 data-aos="slide-left" className="m-4 text-2xl font-bold">
        Contact Me
      </h2>
      <hr data-aos="fade-left" className="border-b border-lime-300" />
      <div data-aos="fade-in" className="grid grid-cols-1 md:grid-cols-2">
        <div className="p-8 space-y-2">
          <h3 className="text-xl font-semibold">Details:</h3>
          <p>
            Hello, I am a full stack software developer who can build software
            and systems from the ground up. Starting with some basic ideas and
            designs I can create servers, UI, applications, databases and any
            other integrations or infrastructure needed for a complete app.
          </p>
          <p>
            I have a lot more projects not included here, either because I don't
            have any good material to showcase them or because they are too
            small to be featured.
          </p>
          <p>
            Note: This website is always under-development for any new projects
            or changes I decide are needed
          </p>
        </div>
        <div className="flex flex-col items-end p-8 space-y-8">
          <a
            className="flex items-center space-x-2 hover:text-lime-300"
            href="https://www.linkedin.com/in/themis-papathemistocleous-5a9924170/"
            target="_blank"
            rel="noreferrer"
          >
            <span className="text-lg font-semibold">LinkedIn:</span>
            <FaLinkedin size="2rem" />
          </a>
          <a
            className="flex items-center space-x-2 hover:text-lime-300"
            href="https://github.com/ThemisP"
            target="_blank"
            rel="noreferrer"
          >
            <span className="text-lg font-semibold">GitHub:</span>
            <FaGithub size="2rem" />
          </a>
          <a
            className="flex items-center max-w-full overflow-hidden hover:text-lime-300"
            href="mailto:themispapathemistocleous@gmail.com"
          >
            <span className="overflow-hidden text-lg font-semibold overflow-ellipsis">
              themispapathemistocleous
            </span>
            <span className="mr-2">@gmail.com</span>
            <FaEnvelope className="flex-shrink-0" size="2rem" />
          </a>
        </div>
      </div>
    </div>
  );
}

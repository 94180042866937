import Main from "./Pages/Main";
import { Route, Routes } from "react-router-dom";
import ComputerGraphics from "./Pages/ComputerGraphics";
import Modelling3D from "./Pages/Modelling3D";
import ComputerVision from "./Pages/ComputerVision";
import ShooterGame from "./Pages/ShooterGame";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { useEffect } from "react";
import Shoplyful from "./Pages/Shoplyful";
import Animation from "./Pages/Animation";
import TourneyRunner from "./Pages/TourneyRunner";
import NonceDiscovery from "./Pages/NonceDiscovery";

function App() {
  useEffect(()=> {
    AOS.init({ duration: 500 });
  }, [])
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/computergraphics" element={<ComputerGraphics />} />
      <Route path="/3dmodelling" element={<Modelling3D />} />
      <Route path="/computervision" element={<ComputerVision />} />
      <Route path="/shootergame" element={<ShooterGame />} />
      <Route path="/shoplyful" element={<Shoplyful />} />
      <Route path="/animation" element={<Animation />} />
      <Route path="/tourneyrunner" element={<TourneyRunner />} />
      <Route path="/noncediscovery" element={<NonceDiscovery />} />
      
    </Routes>
  );
}

export default App;

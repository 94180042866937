import React from "react";
import Helmet from "react-helmet";
import Figure from "../Components/Common/Figure";
import GalleryCarousel from "../Components/Common/GalleryCarousel";
import Footer from "../Components/Footer";
import ProjectNavBar from "../Components/ProjectNavBar";
import styles from "./Pages.module.css";

const images = [
  "/images/shoplyful/shoplyful_logo.jpg",
  "/images/shoplyful/main_page.png",
  "/images/shoplyful/shop_view.png",
  "/images/shoplyful/marketing_site.png",
  "/images/shoplyful/login.png",
  "/images/shoplyful/dashboard_products.png",
  "/images/shoplyful/dashboard_orders.png",
  "/images/shoplyful/dashboard_styling.png",
  "/images/shoplyful/dashboard_settings.png",
];

const navLinks = [
  { text: "Website", location: "#website" },
  { text: "Dashboard", location: "#dashboard" },
  { text: "Server", location: "#server" },
];

export default function Shoplyful() {
  return (
    <div className="bg-zinc-900">
      <Helmet>
        <title>Themis - Shoplyful</title>
      </Helmet>
      <ProjectNavBar title="Shoplyful" navigation={navLinks} />

      <section className="w-full max-w-screen-lg p-4 mx-auto space-y-2 text-white">
        <h1 className="text-2xl font-bold">Introduction</h1>
        <p>
          This is the company I founded at the beginning of 2021. It is a
          platform that allows shop owners to quickly and easily sell their
          products online. There are two categories of such companies, one is
          marketplaces like Amazon, Etsy and e-Bay and the other is website
          builders like Shopify, Wordpress etc. The vision of the company is to
          create a very simplified version of a website builder that would allow
          shop owners to focus on selling their products. In addition, there is
          a website where you can find all shops created with Shoplyful.
        </p>
        <p>
          Everything is hosted in AWS, deployed through automated deployment
          pipelines and setup with "staging" environments to test features
          before they are released in the public versions.
        </p>
        <p>
          It is currently fully functional and working online at{" "}
          <a
            className="text-blue-500 hover:underline"
            href="https://www.shoplyful.com"
            target="_blank"
            rel="noreferrer"
          >
            www.shoplyful.com
          </a>
          . The platform can be split into 3 different sections, the server, the
          dashboard and the main website. The repositories for this project are
          private and the reason why I do not provide a link to GitHub code
          here.
        </p>
      </section>

      <section className="w-full max-w-screen-lg p-4 mx-auto space-y-2 text-white">
        <h1 className="text-2xl font-bold">Gallery</h1>
        <GalleryCarousel images={images} />
      </section>

      <section
        className="w-full max-w-screen-lg p-4 mx-auto space-y-4 text-white"
        style={{ scrollMarginTop: "80px" }}
        id="website"
      >
        <h1 className="text-2xl font-bold">Website</h1>
        <hr className="border-b border-lime-300" />
        <p className="my-4">
          You could describe the website as an online mall, it contains all
          shops created with Shoplyful and allows a customer to browser through
          them and pick where they would like to shop. The main focus here is to
          keep the identity of each of the shops intact and therefore no
          products are displayed, instead only shops and their logos.
        </p>
        <p className="my-4">
          The User interface was created using React and was hosted in AWS.
          Because the website uses all of its subdomains to serve the different
          shops (for example agriplant.shoplyful.com.cy would direct to
          "Agriplant Gardens" shop) there was a very custom setup needed to
          direct all subdomain routes and accept them through AWS Route 53
          towards the AWS Elastic Beanstalk instance that was used to serve the
          website.
        </p>
        <div
          className={`grid gap-4 justify-center sm:grid-cols-2 ${styles.projectDetails}`}
        >
          <li>
            <strong>Figure 1</strong> is the main page of Shoplyful where all
            shops are displayed and grouped into categories. The focus was to
            have a "Netflix" style browsing experience where it would in the
            future be fitted with advnaced recommendation algorithms to maximize
            the user engagement.
          </li>
          <div className="mx-auto">
            <Figure
              img={images[1]}
              alt="figure1"
              caption="Figure 1: Main page"
            />
          </div>
          <li>
            As mentioned, the goal is to keep identity of individual shops
            intact therefore the only indication of "Shoplyful" when you visit a
            shop is a "Powered by" icon on the bottom left.{" "}
            <strong>Figure 2</strong> displays the page of one of the shops. You
            can view all the products and navigate through the different
            categories with an advanced text search featured almost ready to be
            released in the live version. When a user is logged in, they can
            view their shopping cart (bottom right) which is unique for each
            different shop.
          </li>
          <div className="mx-auto">
            <Figure
              img={images[2]}
              alt="figure2"
              caption="Figure 2: Shop page"
            />
          </div>
          <li className="sm:col-span-2">
            Although the main website is hosted in{" "}
            <a
              className="text-blue-500 hover:underline"
              href="https://www.shoplyful.com.cy"
              target="_blank"
              rel="noreferrer"
            >
              www.shoplyful.com.cy
            </a>{" "}
            for marketing purposes there is another website that explains what
            Shoplyful is and contains information for shop owners which is
            located at{" "}
            <a
              className="text-blue-500 hover:underline"
              href="https://www.shoplyful.com"
              target="_blank"
              rel="noreferrer"
            >
              www.shoplyful.com
            </a>{" "}
            (<strong>Figure 3</strong>). This is a very simple website with a
            better design, clearer instructions and some animations intended for
            a better first impression.
          </li>
          <div className="mx-auto sm:col-span-2">
            <Figure
              img={images[3]}
              alt="figure3"
              caption="Figure 3: Marketing Website"
            />
          </div>
          <li>
            For users ease of use, they can log in to Shoplyful through many
            means. They can either create an account directly from Shoplyful or
            they can use the OAuth versions of Google login or Facebook login
            for efficiency.
          </li>
          <div className="mx-auto">
            <Figure img={images[4]} alt="figure4" caption="Figure 4: Login" />
          </div>
        </div>
      </section>

      <section
        className="w-full max-w-screen-lg p-4 mx-auto space-y-4 text-white"
        style={{ scrollMarginTop: "80px" }}
        id="dashboard"
      >
        <h1 className="text-2xl font-bold">Dashboard</h1>
        <hr className="border-b border-lime-300" />
        <p className="my-4">
          The dashboard is the place where shop owners can create and control
          their online shops. It is a fully functional multi-purpose tool for
          anything relevant to their website. They can setup their products
          there, choose different styling for their pages, receive orders and
          setup delivery settings. The dashboard can be accessed through{" "}
          <a
            className="text-blue-500 hover:underline"
            href="https://www.dashboard.shoplyful.com.cy"
            target="_blank"
            rel="noreferrer"
          >
            www.dashboard.shoplyful.com.cy
          </a>
        </p>
        <p className="my-4">
          The Dashboard is integrated with Tidio chatbox which you can get help
          for any questions you may have or problems you may encounter. The
          interfaces are meant to be clear and big containing as little
          functionality as possible to prevent overwhelming the user.
        </p>
        <div
          className={`grid gap-4 justify-center sm:grid-cols-2 ${styles.projectDetails}`}
        >
          <li>
            The first thing you seen when you log into your dashboard is a
            create a new shop step-by-step process. If you already have a shop
            you will be presented with the "products page" (
            <strong>Figure 5</strong>). This page allows you to create
            categories for your products, upload images, setup prices add extra
            options and many more. This gives the power of each shop owner to
            control and setup their products.
          </li>
          <div className="mx-auto">
            <Figure
              img={images[5]}
              alt="figure5"
              caption="Figure 5: Products page"
            />
          </div>
          <li>
            The "orders page" (<strong>Figure 6</strong>) is where a shop can
            see the orders they received. Orders are separated into 5 groups
            Pending, Accepted, Ready for Delivery, Completed and Rejected. A new
            order is automatically placed into the pending group and only when a
            shop owner accepts the order, they will receive the money and the
            can start preparing the order from the pictures and the descriptions
            displayed in each order. Accepted orders can then be updated to show
            to the customers when they have been prepared and are ready for
            delivery and lastly when an order is finished, it can be marked as
            completed to be viewed as "history" of orders. Any orders rejected
            need to be accommpanied with a legitimate reason so that the
            customers are notified. Notifications are sent to all parties, shop
            owners receive an SMS notification whenever a new order arrives and
            customers receive an email when their order is accepted or rejected.
          </li>
          <div className="mx-auto">
            <Figure
              img={images[6]}
              alt="figure6"
              caption="Figure 6: Orders page"
            />
          </div>
          <li>
            The aim of the "styling page" (<strong>Figure 7</strong>) is so that
            shop owners can give a more customized look and feel of their
            website but without too much decisions and difficult design options.
            It currently allows a shop to upload their logo and choose a theme
            color to be applied to their page. This will be enriched with a lot
            of features to allow for different layouts, card designs, gallery
            sections and much more for a very easy but customized experience.
          </li>
          <div className="mx-auto">
            <Figure
              img={images[7]}
              alt="figure7"
              caption="Figure 7: Styling page"
            />
          </div>
          <li>
            Lastly, the "settings page" (<strong>Figure 8</strong>) enables the
            addition of control over how the shops operate. For example a shop
            owner can choose which countries to allow delivery and have
            different delivery options and prices for each one. Access to their
            stripe dashboard and view their demographics.
          </li>
          <div className="mx-auto">
            <Figure
              img={images[8]}
              alt="figure8"
              caption="Figure 8: Settings page"
            />
          </div>
        </div>
      </section>

      <section
        className="w-full max-w-screen-lg p-4 mx-auto space-y-4 text-white"
        style={{ scrollMarginTop: "80px" }}
        id="server"
      >
        <h1 className="text-2xl font-bold">Server</h1>
        <hr className="border-b border-lime-300" />
        <p className="my-4">
          The server handles all of the functionality for the platform. It
          implements user authentication/authorization, stripe integration, shop
          creation and any 3rd party integrations. It was created in NodeJS
          and is up to standard for data privacy and regulations by using BCrypt to encrypt user sensitive
          data, to storing data in a non readable form and employing a permission style system to restrict access
          so that each user has access only to its corresponding data.
        </p>
        <p className="my-4">
          The data is stored in a MongoDB database hosted in Atlas with all of the data
          organized into well thought and architectured models. Relationships between data
          have been modeled to prioritize efficiency and future proofing for some new features
          that will be implemented. Indexing for many fields has been created to allow for performant
          autocomplets and full text search.
        </p>
      </section>
      <Footer />
    </div>
  );
}

import { useEffect, useRef, useState } from "react";

// Hook
export default function useScreenVisible(rootMargin = "0px") {
  const ref = useRef();
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);
  useEffect(() => {
    if (!ref) return;
    const current = ref.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      }
    );
    if (current) {
      observer.observe(current);
    }
    return () => {
      observer.unobserve(current);
    };
  }, [ref, rootMargin]);
  return [ref, isIntersecting];
}
import React from "react";
import Helmet from "react-helmet";
import Figure from "../Components/Common/Figure";
import GalleryCarousel from "../Components/Common/GalleryCarousel";
import Footer from "../Components/Footer";
import ProjectNavBar from "../Components/ProjectNavBar";
import styles from "./Pages.module.css";

const images = [
  "/images/animation/sketch_1.jpg",
  "/images/animation/bodypart_stretch.jpg",
  "/images/animation/anticipation_graph.jpg",
  "/images/animation/constraints.jpg",
  "/images/animation/weighted_hand_rig2.jpg",
  "/images/animation/hand_new.jpg",
  "/images/animation/hierarchy.jpg",
  "/images/animation/body_controls.jpg",
  "/images/animation/hand_controls.jpg",
  "/images/animation/scene.jpg",
];

const navLinks = [
  { text: "Assemble", location: "#assemble" },
  { text: "Dance", location: "#dance" },
];

export default function Animation() {
  return (
    <div className="bg-zinc-900">
      <Helmet>
        <title>Themis - Animation</title>
      </Helmet>
      <ProjectNavBar title="Animation" navigation={navLinks} />

      <section className="w-full max-w-screen-lg p-4 mx-auto space-y-2 text-white">
        <h1 className="text-2xl font-bold">Introduction</h1>
        <p>
          Using Autodesk Maya to create and animate a robot. The process is
          separated into two sections. The first section is the creation of the
          3D model and the animation that takes the individual parts and
          assembles them to form the robot. The second section is the process of
          adding a skeleton to the robot (rigging) and making a dance animation.
          Both sections have a small ~20second video.
        </p>
      </section>

      <section className="w-full max-w-screen-lg p-4 mx-auto space-y-2 text-white">
        <h1 className="text-2xl font-bold">Gallery</h1>
        <GalleryCarousel images={images} />
      </section>

      <section
        className="w-full max-w-screen-lg p-4 mx-auto space-y-4 text-white"
        style={{ scrollMarginTop: "80px" }}
        id="assemble"
      >
        <h1 className="text-2xl font-bold">Assemble</h1>
        <hr className="border-b border-lime-300" />
        <p className="my-4">
          This section briefly describes the process undertaken to produce an
          animation. from the initial stage of pre-production and planning to
          the end result. The animation is showcasing a self-assembling robot
          and attempts to produce realistic and meaningful motions throughout,
          using animation principles and animation tools.
        </p>
        <iframe
          title="Assemble animation"
          className="w-full max-w-lg mx-auto aspect-video"
          src="https://www.youtube.com/embed/VwidtlwjDys"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <div
          className={`grid gap-4 justify-center sm:grid-cols-2 ${styles.projectDetails}`}
        >
          <li>
            <strong>Pre-production and planning: </strong> Using sketches at the
            very beginning was a powerful way of narrowing down the ideas for
            the character to be used. It helped forge the idea of the character,
            the body parts required, the motion those body parts would provide
            and bring the character to life. One of the sketches is shown in
            Figure 1.
            <br />
            In addition to sketches, scripts were created in order to describe
            the motion that I had in mind and try to set up a basic structure
            for the work.
          </li>
          <div className="mx-auto">
            <Figure
              img={images[0]}
              alt="figure1"
              caption="Figure 1: Planning"
            />
          </div>
          <li>
            <strong>Challenges and Highlights:</strong> In the process of
            creating an immersive animation, a number of different techniques
            and animation processes were used. Examples include Set-driven keys,
            Post-infinity curves, Graph editor manipulation, Constraints,
            Disney's Animation Principles, and Camera Motion smoothing. <br />
            Set driven keys were used to extend and contract the robots
            extension parts shown in <strong>Figure 2</strong>. <br />{" "}
            Post-infinity curves were used to animate the gears of the robot's
            "heart" for non-stop motion. <br />
            Constraints added to separate body parts, so that they follow the
            motion of others such as the hand following the extension of the
            other body parts without breaking, shown in{" "}
            <strong>Figure 4</strong>. <br />
            Using Disney's animation principle to add anticipation when legs and
            arms contract in the animation, they first extend showing the
            something will happen, and when they contract they do in a
            spring-like manner. In <strong>Figure 3</strong>, anticipation is
            demonstrated by the initial rise of the graph, and then the spring
            dampening effect is shown in the oscillations close to the end.
          </li>
          <div className="mx-auto max-w-max">
            <Figure
              img={images[1]}
              alt="figure2"
              caption="Figure 2: Leg model"
            />
          </div>
          <div className="grid items-center justify-between w-full grid-cols-1 sm:col-span-2 sm:grid-cols-2 sm:space-x-4">
            <div className="mx-auto max-w-max">
              <Figure
                img={images[2]}
                alt="figure3"
                caption="Figure 3: Anticipation and spring-like motion"
              />
            </div>
            <div className="mx-auto max-w-max">
              <Figure
                img={images[3]}
                alt="figure4"
                caption="Figure 4: hand constraints"
              />
            </div>
          </div>
        </div>
      </section>
      <section
        className="w-full max-w-screen-lg p-4 mx-auto space-y-4 text-white"
        style={{ scrollMarginTop: "80px" }}
        id="dance"
      >
        <h1 className="text-2xl font-bold">Dance</h1>
        <hr className="border-b border-lime-300" />
        <p className="my-4">
          This section briefly describes the process of rigging and the process
          of creating the sequence of animation of the robot getting up from the
          ground, doing 3 dance moves, and lastly a finishing pose as an outro.
          In addition, I added simple textures to the robot to make him more
          appealing.
        </p>
        <iframe
          title="Dance animation"
          className="w-full max-w-lg mx-auto aspect-video"
          src="https://www.youtube.com/embed/c5NQVdmr6dk"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <div
          className={`grid gap-4 justify-center sm:grid-cols-2 ${styles.projectDetails}`}
        >
          <li className="sm:col-span-2">
            <strong>Pre-production and planning:</strong> As with the first
            section of the project, some preparation before I dive straight into
            Maya was carried out. First of all selecting the music that the
            robot would dance to, then creating a small animation script and
            lastly describing the location helped identify some very simple
            props I could use to make the atmosphere and the look of the
            animation better.
          </li>
          <li className="sm:col-span-2">
            <strong>Weighted rigging:</strong> Initially, when starting to add
            joints to the structure of the character, I came across an obstacle.
            I modeled every other part of the character as separate objects
            except the hand. The hand was a single object. I started working in
            adding weighted rig bones to the hand (<strong>Figure 5</strong>)
            but shortly realized that having weighted skinned joints parented
            under unskinned joints would become a very unstable way of working,
            and would become an issue in the future. I soon deleted the hand and
            created a new model splitting the fingers into many different
            objects (<strong>Figure 6</strong>) to keep the consistency of the
            model throughout.
          </li>
          <div className="grid items-center justify-between w-full grid-cols-1 sm:col-span-2 sm:grid-cols-2 sm:space-x-4">
            <div className="mx-auto max-w-max">
              <Figure
                img={images[4]}
                alt="figure5"
                caption="Figure 5: Weighted rigging"
              />
            </div>
            <div className="mx-auto max-w-max">
              <Figure
                img={images[5]}
                alt="figure6"
                caption="Figure 6: Multipart hand"
              />
            </div>
          </div>
          <li className="sm:col-span-2">
            <strong>Inverse Kinematics:</strong> There were two major ways to
            rig the model and add motion. Either Forward kinematics or inverse
            kinematics. Inverse kinematics initially seemed easier and simpler,
            just move one control and the joints will animate accordingly. Soon
            after starting to animate, I realized that the motion inverse
            kinematics provide is limited and most of the time unnatural. This
            led me to do more research before continuing, and after some reading
            and rethinking, I changed the arms of the robot to forward
            kinematics and kept the legs of the robot as inverse kinematics.
            Inverse kinematics are very good when you want to animate something
            attached to something else, e.g. the legs to the ground, that way
            you can move your character but the legs will still be stuck
            correctly to the ground.
          </li>
          <li>
            <strong>Controls and Cleanup:</strong> Animating a skeleton turned
            out to be very messy and often confusing. Therefore after looking at
            other peoples work and researching basic principles online, I
            started cleaning up my model and adding controls to the joints that
            I wanted to animate
            <strong>Figures 8 and 9</strong> show the control circles and
            control points attached to the joints that would be animated, and{" "}
            <strong>Figure 7</strong> shows the hierarchy of objects and bones
            when they were all renamed and structured cleanly.
          </li>
          <div className="mx-auto">
            <Figure
              img={images[6]}
              alt="figure7"
              caption="Figure 7: Object hierarchy"
            />
          </div>
          <div className="grid items-center justify-between w-full grid-cols-1 sm:col-span-2 sm:grid-cols-2 sm:space-x-4">
            <div className="mx-auto max-w-max">
              <Figure
                img={images[7]}
                alt="figure8"
                caption="Figure 8: Body controls"
              />
            </div>
            <div className="mx-auto max-w-max">
              <Figure
                img={images[8]}
                alt="figure9"
                caption="Figure 9: Hand controls"
              />
            </div>
          </div>
          <li>
            Lastly, the creation of the dance floor with 8 different colored
            lights and illuminating square blocks for the Robot to dance on is
            shown in <strong>Figure 10</strong>.
          </li>
          <div className="mx-auto">
            <Figure
              img={images[9]}
              alt="figure10"
              caption="Figure 10: Scene layout and spotlights"
            />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

import React from "react";
import { FaBed, FaCoffee, FaPencilAlt, FaArrowLeft } from "react-icons/fa";
import avatar from "../images/cartoon_avatar.png";
import hero_picture from "../images/hero_section_keyboard.png";
import styles from "./HeroSection.module.css";
const icons = [
  <FaCoffee />,
  <FaBed />,
  <FaPencilAlt />,
  <FaArrowLeft />,
  <FaPencilAlt />,
  <FaPencilAlt />,
  <FaPencilAlt />,
  <FaPencilAlt />,
  <FaPencilAlt />,
];
export default function HeroSection() {
  return (
    <section
      className="w-screen h-screen bg-black bg-no-repeat bg-contain"
      style={{
        backgroundImage: `url(${hero_picture})`,
        backgroundPosition: "center",
      }}
    >
      <div className="flex items-center justify-center w-full h-full bg-black bg-opacity-60">
        <div className="z-10 grid items-center w-full max-w-screen-lg gap-8 p-20 mx-auto md:grid-cols-2">
          <div className="text-white">
            <div className="p-2 rounded-md bg-gradient-to-br from-lime-400 to-lime-200 max-w-max">
              <h1 className="text-3xl font-bold text-black">
                Full stack developer
              </h1>
            </div>
            <p className="my-2 text-lg font-semibold">
              Robust and Reliant software.
            </p>
          </div>
          <div className={styles.avatar}>
            <img src={avatar} alt="avatar" />
            <img src={avatar} alt="avatar" />
            <img src={avatar} alt="avatar" />
            {/* <div className={styles.orbit}>
              {icons.map((icon, i) => {
                let angle = (icons.length - 1 - i) * (360 / icons.length);
                let comparator = angle > 180 ? angle % 180 : 180 - angle;
                comparator = (comparator / 180) * 0.7 + 0.1;
                return (
                  <div
                    key={i}
                    style={{
                      transform: `RotateY(${angle}deg) TranslateZ(200px)`,
                      zIndex: angle > 90 && angle < 270 ? "-1" : "3",
                      backgroundImage: `radial-gradient(circle, #fff, ${
                        comparator * 100
                      }%, #000)`,
                    }}
                  >
                    {icon}
                  </div>
                );
              })}
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

import React from "react";
import { FaCode } from "react-icons/fa";
import usePushTransition from "../Hooks/usePushTransition";
import useScrollPosition from "../Hooks/useScrollPosition";
import ResponsiveNav from "./Common/ResponsiveNav";

const navLinks = [
  { text: "Portfolio", location: "#portfolio" },
  { text: "Contact me", location: "#contact" },
  { text: "Skills", location: "#skills" },
];

export default function NavBar() {
  const [ref, onHover] = usePushTransition();
  const scrollPos = useScrollPosition();
  return (
    <nav className={`transition fixed top-0 z-20 w-full ${scrollPos > 100 ? "bg-zinc-800" : ""}`}>
      <div className="flex items-center justify-between w-full max-w-screen-xl p-4 mx-auto">
        <a href="#top" className="flex items-center space-x-2" >
          <FaCode className="text-lime-300" size="2rem" />
          <h1 className="text-2xl font-bold text-white">Themis</h1>
          <p className="text-xl text-white">Papathemistocleous</p>
        </a>
        <ResponsiveNav convertWidth={768}>
          {({dropdown, closeMenu}) => (
            <div
            ref={!dropdown ? ref : null}
            className={`flex mr-2 text-xl font-semibold text-center text-white
            ${dropdown ? "flex-col space-y-8 items-start p-8":"space-x-8 items-center"} `}
          >
            {navLinks.map((link) => (
              <a
                key={link.text}
                href={link.location}
                onMouseOver={onHover}
                className="transition duration-500 origin-top cursor-pointer"
                onClick={closeMenu}
                >
                {link.text}
              </a>
            ))}
          </div>
          )}
        </ResponsiveNav>
      </div>
    </nav>
  );
}

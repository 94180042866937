import { useEffect, useState } from "react";

export default function useScrollPosition() {
  const [scrollPos, setScrollPos] = useState(document.documentElement.scrollTop); 
  useEffect(() => {
    function setupScroll(ev) {
      setScrollPos(ev.target.documentElement.scrollTop);
    }
    window.addEventListener("scroll", setupScroll);
    return () => window.removeEventListener("scroll", setupScroll);
  }, [setScrollPos])
  return scrollPos
}
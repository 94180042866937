import React from "react";
import Figure from "../Components/Common/Figure";
import GalleryCarousel from "../Components/Common/GalleryCarousel";
import ProjectNavBar from "../Components/ProjectNavBar";
import { FaGithub } from "react-icons/fa";
import styles from "./Pages.module.css";
import Footer from "../Components/Footer";
import Helmet from "react-helmet";

const images = [
  "/images/ipcv/dart1_detected.jpg",
  "/images/ipcv/dart11_detected.jpg",
  "/images/ipcv/dart15_detected.jpg",
  "/images/ipcv/dart13_detected.jpg",
  "/images/ipcv/dart0_detected_magnitude.jpg",
  "/images/ipcv/dart0_detected_houghSpace.jpg",
  "/images/ipcv/dart0_detected_sb3.jpg",
  "/images/ipcv/dart2_detected_magnitude.jpg",
  "/images/ipcv/dart2_detected_houghSpace.jpg",
  "/images/ipcv/dart2_detected_sb3.jpg",
  "/images/ipcv/dart2_detected_sb4.jpg",
  "/images/ipcv/dart5_detected_sb4.jpg",
];

const navLinks = [
  { text: "Viola-Jones", location: "#violajones" },
  { text: "Hough-Space", location: "#houghspace" },
];

export default function ComputerVision() {
  return (
    <div className="bg-zinc-900">
      <Helmet>
        <title>Themis - Computer Vision</title>
      </Helmet>
      <ProjectNavBar
        title="Computer Vision"
        github="https://github.com/ThemisP/Coursework1_IPCV"
        navigation={navLinks}
      />

      <section className="w-full max-w-screen-lg p-4 mx-auto space-y-2 text-white">
        <h1 className="text-2xl font-bold">Introduction</h1>
        <p>
          This project is focused on creating an algorithm that can correctly
          identify dart boards on images. The start of the algorithm is a
          Viola-Jones detector that is given many positive and negative images
          in order to train the classifier and then it is paired with both a
          line and circle detector in hough space so as to correctly identify
          the lines of a dart board. The language used here is C++.
        </p>
      </section>

      <section className="w-full max-w-screen-lg p-4 mx-auto space-y-2 text-white">
        <h1 className="text-2xl font-bold">Gallery</h1>
        <GalleryCarousel images={images} />
      </section>

      <section
        className="w-full max-w-screen-lg p-4 mx-auto space-y-4 text-white"
        style={{ scrollMarginTop: "80px" }}
        id="violajones"
      >
        <h1 className="text-2xl font-bold">Viola-Jones Detection</h1>
        <hr className="border-b border-lime-300" />
        <p className="my-4">
          Using 500 samples of positive images (ie. images of dart boards) taken
          at different angles, and another 500 images showing what is not a dart
          board, the Viola-Jones classifier was trained to try and detect dart
          boards correctly. There are 15 dart images in which the algorithm is
          tested on, the red squares are predefined areas which we consider
          ground truth and the green squares are the algorithms results. The
          results as shown in <strong>Figures 1, 2, 3 and 4</strong> reveal that
          the classifier is not strong enough and although for example in all
          four images the classifier finds the dartboards (true positive rate
          100%), it also finds many false positives which is not ideal.
          Depending on the use of the classifier sometimes the ideal is this but
          we are aiming for accuracy here therefore the results are not good
          yet.
        </p>
        <div className="grid justify-center gap-4 sm:grid-cols-2">
          <div className="mx-auto">
            <Figure img={images[0]} alt="figure1" caption="Figure 1: Example" />
          </div>
          <div className="mx-auto">
            <Figure img={images[1]} alt="figure2" caption="Figure 2: Example" />
          </div>
          <div className="mx-auto">
            <Figure img={images[2]} alt="figure3" caption="Figure 3: Example" />
          </div>
          <div className="mx-auto">
            <Figure img={images[3]} alt="figure4" caption="Figure 4: Example" />
          </div>
        </div>
      </section>
      <section
        className="w-full max-w-screen-lg p-4 mx-auto space-y-4 text-white"
        style={{ scrollMarginTop: "80px" }}
        id="houghspace"
      >
        <h1 className="text-2xl font-bold">Hough-Space Detection</h1>
        <hr className="border-b border-lime-300" />
        <p >
          <strong>Hough Space Line Detector:</strong> To extend the Viola-Jones
          detector and improve our results, we implemented a Hough Space Line
          Detector. Instead of taking into consideration the whole image for our
          Hough Space, we used the resulting detected boxes from the Viola-Jones
          to find the threshold magnitude image of each box{" "}
          <strong>Figures 5 and 8</strong>. Then we implemented the Hough
          Transform for Line detection in each of the boxes{" "}
          <strong>Figures 6 and 9</strong>. We set the threshold to 70 so that
          only the lines with the higher votes are considered, and then we set
          as a condition that there are at least 10 lines in each of the
          detected boxes. The boxes that fulfilled the conditions above were
          classified as valid detections, and were kept in the image
          <strong>Figure 7</strong>.
        </p>
        <div className="grid justify-center gap-4 sm:grid-cols-3">
          <div className="mx-auto">
            <Figure
              img={images[4]}
              alt="figure5"
              caption="Figure 5: Threshold Magnitude"
            />
          </div>
          <div className="mx-auto">
            <Figure
              img={images[5]}
              alt="figure6"
              caption="Figure 6: Hough space"
            />
          </div>
          <div className="mx-auto">
            <Figure
              img={images[6]}
              alt="figure7"
              caption="Figure 7: Detection"
            />
          </div>
          <div className="mx-auto">
            <Figure
              img={images[7]}
              alt="figure8"
              caption="Figure 8: Threshold Magnitude"
            />
          </div>
          <div className="mx-auto">
            <Figure
              img={images[8]}
              alt="figure9"
              caption="Figure 9: Hough space"
            />
          </div>
          <div className="mx-auto">
            <Figure
              img={images[9]}
              alt="figure10"
              caption="Figure 10: Detection"
            />
          </div>
        </div>
        <ul className="pl-4 space-y-2 list-disc">
          <li>
            <strong>Merits and Limitations:</strong> There is a noticeable
            increase in the average F1-score of the table below, compared to the
            average F1-score of the Viola-Jones detection alone. However,
            although in most images our improved detector managed to have a very
            high accuracy by achieving high TPR and very low FPR, such as the
            one shown in Figure 13, in some images it sabotaged the results by
            discarding previously correctly detected dartboards. An example of
            the latter is shown in Figure 16. There is a noticeable increase in
            the accuracy of the algorithm since it manages to decrease the false
            positives of the image as it considers stricter rules for
            classifying a section of the image as a dart board. The limitation
            of the classifier is that it does not consider other sections of the
            image, only those detected by Viola-Jones and sometimes this will
            not even help the classifier identify the dart board at all, such a
            result is shown in <strong>Figure 10</strong>.
          </li>
          <li>
            <strong>Rationale:</strong> The idea behind this way of implementing
            the combination of the two algorithms in conjunction, is that if you
            "relax" the Viola-Jones classifier enough, there is a point were you
            can achieve 100% true positive rate. If at that point then having
            stricter rules on top of Viola-Jones would help decrease the
            enormous false positive rate and become accurate enough with
            extremely good efficiency since the Hough Space Line detector would
            not operate on the whole image.
          </li>
          <li>
            <strong>Hough Transform Circle Detector:</strong> In addition to the
            line detector added on top of the Viola-Jones detector, Hough
            Transform Circle Detection can allow for a more accurate and better
            detector. As mentioned previously by "relaxing" a detection
            algorithm we can achieve higher true positive rate but also false
            positive rate as well. Therefore we lowered the threshold of the
            line detector and added the Circle detector creating a complex
            network of algorithms working in conjunction to produce a more
            accurate result. As an example the results for the image in{" "}
            <strong>Figure 10</strong> were drastically improved as shown by{" "}
            <strong>Figure 11</strong> and also <strong>Figure 12</strong>.
          </li>
        </ul>
        <div className="grid justify-center gap-4 sm:grid-cols-2">
          <div className="mx-auto">
            <Figure
              img={images[10]}
              alt="figure11"
              caption="Figure 11: Result"
            />
          </div>
          <div className="mx-auto">
            <Figure
              img={images[11]}
              alt="figure12"
              caption="Figure 12: Result"
            />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import svelteLogo from "../images/Svelte_Logo.png";
import Skill from "./Common/Skill";
import {
  FaReact,
  FaNodeJs,
  FaJava,
  FaPython,
  FaHtml5,
  FaJs,
  FaDatabase,
  FaGit,
  FaAws,
  FaFigma,
  FaPaintBrush,
  FaCubes,
  FaUnity,
  FaEdit,
  FaCss3,
  FaCode
} from "react-icons/fa";

export default function SkillsSection() {
  return (
    <div
      className="w-full max-w-screen-xl p-4 mx-auto text-white"
      style={{ scrollMarginTop: "60px" }}
      id="skills"
    >
      <h2 data-aos="slide-left" className="m-4 text-2xl font-bold">
        Skills
      </h2>
      <hr data-aos="fade-left" className="border-b border-lime-300" />
      <div className="grid justify-center grid-cols-1 gap-4 p-8 sm:gap-8 md:grid-cols-3">
        <Skill icon={<FaJs />} text="JavaScript" progress={95} />
        <Skill icon={<FaHtml5 />} text="HTML-5" progress={95} />
        <Skill icon={<FaCss3 />} text="CSS-3" progress={95} />
        <Skill icon={<FaPython />} text="Python" progress={80} />
        <Skill icon={<FaCode />} text="C#" progress={80} />
        <Skill icon={<FaJava />} text="Java" progress={70} />
        <Skill icon={<FaReact />} text="React" progress={95} />
        <Skill icon={<FaNodeJs />} text="NodeJS" progress={95} />
        <Skill
          icon={
            <img style={{ height: "1.5rem" }} src={svelteLogo} alt="svelte" />
          }
          text="Svelte"
          progress={90}
        />
        <Skill icon={<FaUnity />} text="Unity" progress={60} />
        <Skill icon={<FaDatabase />} text="MongoDB" progress={80} />
        <Skill icon={<FaDatabase />} text="PostgreSQL" progress={80} />
        <Skill icon={<FaGit />} text="Git" progress={90} />
        <Skill
          icon={<FaAws />}
          text="Amazon Web Services"
          progress={70}
        />
        <Skill icon={<FaFigma />} text="Figma" progress={60} />
        <Skill icon={<FaPaintBrush />} text="Photoshop" progress={50} />
        <Skill icon={<FaCubes />} text="Blender" progress={50} />
        <Skill icon={<FaEdit />} text="Web Scraping" progress={60} />
      </div>
    </div>
  );
}

const projects = [
  {
    title: "Shoplyful",
    img: "/images/shoplyful/shoplyful_logo.jpg",
    description: "SaaS platform for e-commerce.",
    style: {
      backgroundPosition: "center",
    },
    link: "/shoplyful"
  },
  {
    title: "3D Modelling",
    img: "/images/3d_graphics.png",
    description: "AutoDesk Maya - 3D recreation of a bar.",
    style: {
      backgroundPosition: "left",
    },
    link: "/3dmodelling"
  },
  {
    title: "Computer Graphics",
    img: "/images/computer_graphics/ray_tracer_final.png",
    description: "Raytracer & Rasterizer build from scratch with OpenGL.",
    style: {
      backgroundPosition: "center",
    },
    link: "/computergraphics"
  },
  {
    title: "Computer Vision",
    img: "/images/ipcv.jpg",
    description:
      "Image processing and computer vision for the detection of dart boards in images.",
    style: {
      backgroundPosition: "left",
    },
    link: "/computervision"
  },
  {
    title: "Chain Reaction",
    img: "/images/games_project.jpg",
    description:
      "Multiplayer game created in unity and socket server through .NET Framework.",

    style: {
      backgroundPosition: "center",
    },
    link: "/shootergame"
  },
  {
    title: "Tourney Runner",
    img: "/images/tourney_runner.png",
    description: "Web APP for game tournament creation.",
    style: {
      backgroundPosition: "left",
    },
    link: "/tourneyrunner"
  },
  {
    title: "Animation",
    img: "/images/dance_animation_main.png",
    description: "Robot assemling and dance animation.",
    style: {
      backgroundPosition: "center",
    },
    link: "/animation"
  },
  {
    title: "Crypto technology",
    img: "/images/code_background.jpg",
    description: "Bitcoin Proof of Work algorithm run on multiple instances of AWS cloud for performance boost.",
    style: {
      backgroundPosition: "center",
    },
    link: "/noncediscovery"
  },
];

export default projects;

import React from "react";
import ContactSection from "../Components/ContactSection";
import Footer from "../Components/Footer";
import HeroSection from "../Components/HeroSection";
import NavBar from "../Components/NavBar";
import ProjectsSection from "../Components/ProjectsSection";
import SkillsSection from "../Components/SkillsSection";

export default function Main() {
  return (
    <div className="overflow-x-hidden bg-zinc-900">
      <NavBar />
      <HeroSection />
      <ProjectsSection />
      <ContactSection />
      <SkillsSection />
      <Footer />
    </div>
  );
}

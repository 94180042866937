import React from "react";

export default function Figure({ img, alt, caption }) {
  return (
    <div className="relative w-full overflow-hidden rounded-md max-w-max">
      <img src={img} alt={alt} />
      {caption && (
        <p className="absolute bottom-0 w-full p-2 font-semibold bg-black bg-opacity-50">
          {caption}
        </p>
      )}
    </div>
  );
}

import React from "react";
import Helmet from "react-helmet";
import Figure from "../Components/Common/Figure";
import Footer from "../Components/Footer";
import ProjectNavBar from "../Components/ProjectNavBar";
import styles from "./Pages.module.css";

const images = [
  "/images/nonce/16zeros_graph.png",
  "/images/nonce/20zeros_graph.png",
  "/images/nonce/24zeros_graph.png",
  "/images/nonce/28zeros_graph.png",
];

export default function NonceDiscovery() {
  return (
    <div className="bg-zinc-900">
      <Helmet>
        <title>Themis - Nonce Discovery</title>
      </Helmet>
      <ProjectNavBar
        title="Nonce Discovery - Crypto"
        github="https://github.com/ThemisP/AWS_Cloud_Nonce_Discovery"
      />

      <section className="w-full max-w-screen-lg p-4 mx-auto space-y-2 text-white">
        <h1 className="text-2xl font-bold">Introduction</h1>
        <p>
          This is a very technical project and there are no pictures to present
          it. It is mostly a performance comparison when running the algorithm
          under multiple servers and instances in AWS cloud.
        </p>
        <p>
          We have all come to know bitcoin in the recent years and many have
          studied it worked with it, and even created similar and better
          upgrades of that technology. The crypto space is growing very rapidly
          and it seems like it is going to become a very big part of our future.
        </p>
        <p>
          We all have that one friend who is always nagging us to start mining
          bitcoin! This project is focused on a small subset of bitcoin mining,
          what we call Golden Nonce Discovery and it is part of the "Proof of
          Work" process used in bitcoin mining in order to secure transactions
          (blocks) for security.
        </p>
        <p>
          The idea of the nonce discovery is that when you want to secure and
          encrypt some data, you would use what we call hashing algorithms.
          These algorithms "encode" the data and make it very secure to be send
          to other users without having the ability for them to alter them. An
          additional step is to add a random number to those data before you
          hash them making the encoding even more secure. This random number is
          what we call the nonce.
        </p>
        <p>
          It is not completely random, it comes from a process so that it can be
          generated within some constrains. This process is what miners go
          through and the reward for putting their computer resources is a
          commission on the transaction. This algorithm is what we call a "Proof
          of work", algorithm because it very hard to get a result but you can
          easily check wether the result is correct!.
        </p>
        <p>
          In a simplified way, what miners do is that they run their computer
          testing numbers (0, 1, 2, 3, 4 etc...) on an algorithm and checking if
          the result they get is correct, whenever they find a correct answer,
          they can use it to secure a transaction, we call that answer a Golden
          Nonce.
        </p>
        <p>
          The focus of this project was to attempt to automatically create
          instances in AWS cloud and deploy the code with different parameters.
          So that instead of having 1 computer running the algorithm from 0-1
          000 000 (per say), we could have 10 computers running their
          corresponding part (1st instance 0 - 100 000, 2nd 100 000 - 200 000,
          etc...).
        </p>
        <p>
          There is a python script which creates however many instances you
          specify to the connected AWS account. It works in stages:
          <ol className="pl-4 list-decimal">
            <li>create instances based on a custom Amazon Machine Image.</li>
            <li>
              create queue in the Simple Queue Service if the queue with the
              name "cc queue" does not exist.
            </li>
            <li>
              wait and check every 10 seconds if the created instances are
              running.
            </li>
            <li>
              use Paramiko SSH client to connect to each instance and run the
              Nonce discovery script.
            </li>
            <li>
              wait for any instance to send a result to the SQS queue and once
              it does, print it as the result. (If there are multiple messages,
              print all of them and consider the fastest one.
            </li>
            <li>delete the SQS queue.</li>
            <li>delete the created instances</li>
          </ol>
        </p>
        <p>
          Lastly there is another simple script that is the code for the nonce
          discovery. The nonce discovery can increase in difficulty by making
          the acceptable answers stricter. This algorithm check the leading
          zeros in the binary format of the answer, if they are of some amount
          or more then the answer is correct. The following graphs show the time
          difference when increasing the amount of instances to run the code. It
          was run for 16, 20, 24 and 28 leading zeros.
        </p>
        <div
          className={`grid gap-4 justify-center sm:grid-cols-2 ${styles.projectDetails}`}
        >
          <div className="grid items-center justify-between w-full grid-cols-1 sm:col-span-2 sm:grid-cols-2 sm:space-x-4">
            <div className="mx-auto max-w-max">
              <Figure img={images[0]} alt="figure1" />
            </div>
            <div className="mx-auto max-w-max">
              <Figure img={images[1]} alt="figure2" />
            </div>
          </div>
          <div className="grid items-center justify-between w-full grid-cols-1 sm:col-span-2 sm:grid-cols-2 sm:space-x-4">
            <div className="mx-auto max-w-max">
              <Figure img={images[2]} alt="figure3" />
            </div>
            <div className="mx-auto max-w-max">
              <Figure img={images[3]} alt="figure4" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

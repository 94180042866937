import React from "react";
import Helmet from "react-helmet";
import Figure from "../Components/Common/Figure";
import GalleryCarousel from "../Components/Common/GalleryCarousel";
import Footer from "../Components/Footer";
import ProjectNavBar from "../Components/ProjectNavBar";
import styles from "./Pages.module.css";

const images = [
  "/images/tourney_runner/TourneyRunner_logo.png",
  "/images/tourney_runner/initial_look.png",
  "/images/tourney_runner/usecase.jpg",
];

const navLinks = [
  { text: "Front-end", location: "#frontend" },
  { text: "Back-end", location: "#backend" },
];

export default function TourneyRunner() {
  return (
    <div className="bg-zinc-900">
      <Helmet>
        <title>Themis - Tourney Runner</title>
      </Helmet>
      <ProjectNavBar
        title="Tourney Runner"
        github="https://github.com/ThemisP/TourneyRunner_ServerAndApp"
        navigation={navLinks}
      />

      <section className="w-full max-w-screen-lg p-4 mx-auto space-y-2 text-white">
        <h1 className="text-2xl font-bold">Introduction</h1>
        <p>
          This is a project undertaken with a team of 6, with me attempting the
          role of lead programmer. The idea is to create a front-end and a
          back-end that can support an online interactive application. The
          functionality of the application is to create tournaments between
          users for various purposes, whether to compete at a friendly 8 people
          pool tournament or a big football tournament between 32 or more teams.
        </p>
        <p>
          <strong>Technologies:</strong> Java, SpringBoot, JQuery-Mobile,
          Javascript, HTML, CSS.
        </p>
      </section>

      <section className="w-full max-w-screen-lg p-4 mx-auto space-y-2 text-white">
        <h1 className="text-2xl font-bold">Gallery</h1>
        <GalleryCarousel images={images} />
      </section>

      <section
        className="w-full max-w-screen-lg p-4 mx-auto space-y-4 text-white"
        style={{ scrollMarginTop: "80px" }}
        id="frontend"
      >
        <h1 className="text-2xl font-bold">Front-end</h1>
        <hr className="border-b border-lime-300" />
        <p className="my-4">
          At the beginning of the project, the minimum viable product (MVP) was
          identified so as to try and stick to a strict plan, develop something
          that works and if time permitted, add extra functionality. The MVP
          plan was to have the most common tournament structure added which is
          knockouts tree with users that can easily create tournaments and add
          rules such as max game points. Also have a very user friendly
          interface with users able to simply join tournaments with invitations
          or codes.
        </p>
        <iframe
          title="Overview"
          className="w-full max-w-lg mx-auto aspect-video"
          src="https://www.youtube.com/embed/OsCoKV5A8A4"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <div
          className={`grid gap-4 justify-center sm:grid-cols-2 ${styles.projectDetails}`}
        >
          <li>
            There were many plans and ideas in the team at the start of the
            development, therefore we started templating some of the ideas and
            testing user interfaces in an attempt to find the most user friendly
            and aesthetically pleasing layout. Such an early stage plan is shown
            in <strong>Figure 1</strong>.
          </li>
          <div className="mx-auto">
            <Figure
              img={images[1]}
              alt="figure1"
              caption="Figure 1: Initial plans"
            />
          </div>
          <li className="sm:col-span-2">
            The application has a very simple use case diagram{" "}
            <strong>Figure 2</strong> that shows how simple and effective we
            planned the application to be. Just as explained above we aimed for
            simplicity and ease of use.
          </li>
          <div className="mx-auto sm:col-span-2">
            <Figure
              img={images[2]}
              alt="figure2"
              caption="Figure 2: Usage diagram"
            />
          </div>
          <li className="sm:col-span-2">
            <strong>Technical:</strong> As mentioned in the introduction for the
            front-end development, HTML, CSS and Javascript was used with the
            JQuery-Mobile library. The specific library is a single page
            application format that provides good widgets and structures that
            allow for development of a website in an application style capable
            of running smoothly in a mobile phone.
          </li>
        </div>
        <iframe
          title="Promotional Video"
          className="w-full max-w-lg mx-auto aspect-video"
          src="https://www.youtube.com/embed/wBX-9YcrtDM"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </section>

      <section
        className="w-full max-w-screen-lg p-4 mx-auto space-y-4 text-white"
        style={{ scrollMarginTop: "80px" }}
        id="backend"
      >
        <h1 className="text-2xl font-bold">Back-end</h1>
        <hr className="border-b border-lime-300" />
        <p className="my-4">
          The backend was developed in Java, using Spring Framework. A REST API
          was created for the application to interact with the server and store
          important information online. The underlying database used was H2, an
          integrated database, for testing purposes and fast development but was
          planned to be replaced with MySQL later.
        </p>
        <p className="my-4">
          The API supported user registration and login, users creating and
          generating a randomized Tournament with all the participants. Editing
          details of individual games and automatically advancing the winning
          team to the next round. Deleting tournaments. Changing Profile
          nicknames and viewing a number of statistics such as number of
          tournaments won, number of tournaments participated and more. There
          was planned functionality for allowing creation of tournaments for
          teams instead of individuals and also support for people editing their
          profiles, adding profile pictures and more. In addition, different
          tournament types would be later implemented and supported, such as
          leagues.
        </p>
      </section>

      <Footer />
    </div>
  );
}

import React from "react";
import Figure from "../Components/Common/Figure";
import GalleryCarousel from "../Components/Common/GalleryCarousel";
import ProjectNavBar from "../Components/ProjectNavBar";
import { FaGithub } from "react-icons/fa";
import styles from "./Pages.module.css";
import Footer from "../Components/Footer";
import Helmet from "react-helmet";

const images = [
  "/images/duo_shooter/Logo_circle_design2.jpg",
  "/images/duo_shooter/phase_2_chain.png",
  "/images/duo_shooter/spring_force_formula.jpg",
  "/images/duo_shooter/fov-mesh.png",
  "/images/duo_shooter/upgrade_Screen.jpg",
  "/images/duo_shooter/main_server.jpg",
  "/images/duo_shooter/client_network.png",
  "/images/duo_shooter/trello_boards.jpg",
];

const navLinks = [
  { text: "Gameplay", location: "#gameplay" },
  { text: "Server", location: "#server" },
];

export default function ShooterGame() {
  return (
    <div className="bg-zinc-900">
      <Helmet>
        <title>Themis - Multiplayer Game</title>
      </Helmet>
      <ProjectNavBar title="Multiplayer Game" navigation={navLinks} />

      <section className="w-full max-w-screen-lg p-4 mx-auto space-y-4 text-white">
        <h1 className="text-2xl font-bold">Introduction</h1>
        <p>
          Game Overview: "Chain Reaction is a top-down shooter, battle royal
          style game with limited weapon upgrades. Start the game in a prison
          chained to a teammate and scavenge the map for healing kits and weapon
          upgrades. Prepare yourself for enemy team encounters and cooperate
          with your teammate to stay alive until the end, after all you are
          chained together so your movement is dependent on each other. Be
          careful of the storm closing in on you it is deadly. Last team alive
          gets to escape the prison!"
        </p>
        <p>
          An attempt at creating a multiplayer battle-royale style top-down
          shooter game. In a team fo 6, we used <strong>Unity</strong> to create
          the game, <strong>C# .Net Framework</strong> to create a server that
          hosts multiple players and runs game Logic and also used{" "}
          <strong>Autodesk Maya</strong> for the creation of 3D assets used in
          our level. I will overview technical aspects of the process and at the
          end also explain my role and the tasks I undertook as a lead
          programmer.
        </p>
        <p>
          At the very early stages of implementation, we decided to split the
          work into 3 pairs and therefore have a simple separation of workload
          between people. The first group was the Graphics group, responsible
          for the creation of 3D assets, level design, and aesthetics. The
          second was the Gameplay group, responsible for the client-side
          game-related content, such as implementing movement, upgrades, and
          other gameplay features. Lastly, the third group was the Server group
          responsible for creating a server to host multiple client
          communications and allow for the multiplayer experience that a
          battle-royale game needs to offer. As I was part of mostly the
          gameplay and server team I will discuss those parts in detail in the
          following 2 sections and I will also add a third section discussing my
          contributions to the project.
        </p>
      </section>

      <section className="w-full max-w-screen-lg p-4 mx-auto space-y-2 text-white">
        <h1 className="text-2xl font-bold">Gallery</h1>
        <GalleryCarousel images={images} />
      </section>

      <section
        className="w-full max-w-screen-lg p-4 mx-auto space-y-4 text-white"
        style={{ scrollMarginTop: "80px" }}
        id="gameplay"
      >
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold">Gameplay</h1>
          <a
            href="https://github.com/ThemisP/GamesProject/"
            target="_blank"
            rel="noreferrer"
            className="flex items-center space-x-2 hover:text-lime-300"
          >
            <span className="text-lg font-semibold">GitHub:</span>
            <FaGithub size="2rem" />
          </a>
        </div>
        <hr className="border-b border-lime-300" />
        <p>
          Our game is a top-down, battle-royale style shooter aimed for massive
          multiplayer play. Despite the genre of our game, there is a small
          back-story that explains the setting of our arena. Our game takes
          place inside a prison, where the inmates were used as lab rats in
          scientific experiments designed to evolve the human race. The
          experiments were not leading to anywhere and the prisoners had to be
          exterminated after the failure so that no one could ever know what was
          happening. This small back-story sets the design of the arena such
          that there is a prison like map around the outskirts that eventually
          leads to a laboratory filled with surgery tables, to Liquid tanks with
          humans inside and even big boiling tanks of acid. In order to
          eliminate all the prisoners, a huge gas attack was unleashed on the
          prison surrounding it and rushing towards the middle.
        </p>
        <p>
          The controls for the players were very simple, use "WASD" for moving
          around, use the mouse to aim, left click to fire and right-click to
          use the ability dodge. As a design choice, we decided to have the
          bullets move slower than usual so that players could potentially dodge
          them and with an added ability, "dodge", that made them invulnerable
          for a fraction of a second. In addition, the game was designed to be
          played in pairs. We wanted to keep the controls of the player simple,
          in order to add a layer of complexity to the game in the interaction
          between the two players. Making use of the prison theme of our game,
          we created a chain that binds the two players together.
        </p>
        <iframe
          title="Early Prototype"
          className="w-full max-w-lg mx-auto aspect-video"
          src="https://www.youtube.com/embed/K2sGvPGt5D0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <h4 className="text-2xl font-bold">Chain</h4>
        <div
          className={`grid gap-4 justify-center sm:grid-cols-2 ${styles.projectDetails}`}
        >
          <li>
            Implementing a chain between two players was one of the more
            challenging tasks we tackled as a team. Unity's built-in
            functionality was not sufficient as we aimed for a very customizable
            solution and one that would allow us to port it onto the server-side
            later for the multiplayer aspect. After some research, I learned
            that many people attempting to simulate a rope would do it using
            spring physics and after reading some spring mechanics, I designed a
            simple system shown in <strong>Figure 1</strong>.
          </li>
          <div className="mx-auto">
            <Figure
              img={images[1]}
              alt="figure1"
              caption="Figure 1: Chain structure"
            />
          </div>
          <li>
            The chain works by instantiating chain nodes (white boxes in{" "}
            <strong>Figure 1</strong>) that act as intermediate points in a
            chain that simulate forces between one another. A chain can be
            instantiated with as many chain nodes as needed (the more nodes, the
            more realistic the chain) and each chain node pulls on to its
            predecessor using spring forces. If a chain node gets far apart from
            its linked nodes, it is pulled back towards them. The equation for
            spring is shown in <strong>Figure 2</strong>, and is coded with an
            added dampening constant that counters that force. (force = Negative
            Tension Constant * Displacement - Dampening Constant * Velocity
            Magnitude)
          </li>
          <div className="mx-auto">
            <Figure
              img={images[2]}
              alt="figure2"
              caption="Figure 2: Spring Force formula"
            />
          </div>
        </div>
        <h4 className="text-2xl font-bold">Fog of War</h4>
        <div
          className={`grid gap-4 justify-center sm:grid-cols-2 ${styles.projectDetails}`}
        >
          <li>
            Another fundamental aspect of the gameplay is the Fog of War. This
            implements a limited vision system such that players can only view
            what their actual character and their teammate's character is able
            to view. This enabled players to hide from enemies or even ambush
            enemies without being noticed when they enter the screen range of
            them. This is a simple mechanic but very powerful that adds depth to
            the game and more interactions between teams.
          </li>
          <div className="mx-auto">
            <Figure
              img={images[3]}
              alt="figure3"
              caption="Figure 3: Fog of War"
            />
          </div>
          <li className="sm:col-span-2">
            The fog of war was done by casting multiple rays in a circle from
            the player, which detect whether or not they hit an object. The edge
            detection of the rays becomes more accurate as the density of the
            rays increases. In order to improve the performance of the edge
            detection, an optimisation was applied. If a ray cast detects an
            object and the next one doesn't, this is normally seen as the end of
            the object, but the actual edge is in-between them. In order to get
            closer to the actual edge, a method similar to binary search was
            used, which casts another ray at the mid point between them and
            checks on which side the actual edge is. This is done for a couple
            of iterations to increase the precision of the edge detection. This
            means that the field of view doesn't need to have a really high
            density in order to accurately detect objects.
          </li>
          <li className="sm:col-span-2">
            The ray casts are used to draw a mesh around the player (
            <strong>Figure 3</strong>), which is then drawn onto a texture file
            from the perspective of an orthographic camera that sees the whole
            map. The texture is blurred and then projected onto the map by a
            projector put in the same place as the camera. The shader of the
            projector makes it so that only the area within the field of view
            mesh is lighted, while the rest of the map is in a fog of war. The
            projector also blends any new textures, which results in a smooth
            transition.
          </li>
        </div>
        <h4 className="text-2xl font-bold">Fog of War</h4>
        <div
          className={`grid gap-4 justify-center sm:grid-cols-2 ${styles.projectDetails}`}
        >
          <li>
            Every player starts with a basic pistol that could later upgrade to
            one of three options, a rifle that has a high fire rate,
            medium-range, and medium-damage, a shotgun that has low fire rate,
            low-range, high-damage, and 3 bullets spread and lastly a sniper
            that has low fire rate, high-range, and high damage. Players have to
            find upgrade tokens that are randomly scattered around the map and
            use the upgrade menu to upgrade their weapons as shown in{" "}
            <strong>Figure 4</strong>. In addition, other than upgrade tokens
            there are health packs scattered as well that help the player regain
            some health.
          </li>
          <div className="mx-auto">
            <Figure
              img={images[4]}
              alt="figure4"
              caption="Figure 4: Upgrade Menu"
            />
          </div>
        </div>
      </section>

      <section
        className="w-full max-w-screen-lg p-4 mx-auto space-y-4 text-white"
        style={{ scrollMarginTop: "80px" }}
        id="server"
      >
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold">Server</h1>
          <a
            href="https://github.com/ThemisP/ServerApplication"
            target="_blank"
            rel="noreferrer"
            className="flex items-center space-x-2 hover:text-lime-300"
          >
            <span className="text-lg font-semibold">GitHub:</span>
            <FaGithub size="2rem" />
          </a>
        </div>
        <hr className="border-b border-lime-300" />
        <p>
          In the early stages of planning, deciding which technology to use for
          the development of the multiplayer aspect of the game was difficult.
          Nobody in the team had prior knowledge of how multiplayer is done in
          games. It was clear that since we were building a battle-royale style
          game that we needed a client-server style of communication. Initially,
          we discussed the possibility of using <strong>Unity's</strong>{" "}
          built-in <strong>UNet</strong> functionality but soon realized that it
          didn't support the client-server model.
        </p>
        <p>
          Then discovered <strong>Photon</strong>, which is a framework, that is
          easy to use and fast to implement. It has support for Unity and also
          its own cloud <strong>(Photon cloud)</strong> which supports very easy
          deployment. We started implementing connecting players in a server,
          then implemented movement in multiplayer together with movement
          prediction and smoothing. As the game progressed and needed more and
          more specific server-side functionality, we ran into many problems.
          Photon was a very easy solution but with a significant limitation in
          customizability.
        </p>
        <p>
          Lastly, after more research, we decided to build our own server
          implementation, as a <strong>C# console</strong> application and using{" "}
          <strong>.NET Framework WebSockets</strong> which is further expanded
          upon here.
        </p>
        <h4 className="text-2xl font-bold">Architecture</h4>
        <div
          className={`grid gap-4 justify-center sm:grid-cols-2 ${styles.projectDetails}`}
        >
          <li>
            The server is based on a singleton class called "network". "Network"
            is a layer that controls all traffic for the server both inbound and
            outbound. Inbound traffic gets processed using the
            ServerHandlePackets and outbound using another ServerSendPackets.
            Each different client connecting to the server is stored as an
            instance of the Client class which keeps data of the connected
            clients and is used for identification and broadcasting of data. The
            state of the game is also stored in the server through many classes
            such as Player, GameHandler, and RoomHandler. Storing player's data
            such as location, health, and weapon allows the game to have a
            consistent state throughout all the clients.
          </li>
          <div className="mx-auto">
            <Figure
              img={images[5]}
              alt="figure5"
              caption="Figure 5: Server Architecture"
            />
          </div>
        </div>
        <h4 className="text-2xl font-bold">Communication</h4>
        <li>
          Improving the server in order to allow it to host multiple clients
          smoothly meant that we needed to think ahead and implement efficient
          ways of communication between server and clients. Working with
          WebSockets in .NET Framework meant that we had a lot of control over
          the communication methods. Our main efficiency of the server was the
          inclusion of different types of communication protocols.
          <strong>Transmission Control Protocol (TCP)</strong> is a way of
          communicating that includes and handshake and a validation step, which
          meant that there was a consistency when communicating through this
          protocol and the sender of a packed is notified if the communication
          was successful. In contrast,{" "}
          <strong>User Datagram Protocol (UDP)</strong> is a method of
          communication that simply sends data without any verification or
          "callback", which simply put, a sender sends information without
          caring whether the receiver has received them. Therefore, TCP is
          reliable but has a time overhead, whereas UDP is unreliable but fast.
          TCP was used in communicating important player data such as health and
          coins that were necessary for players to keep consistent. UDP was
          mainly used for player and bullet positioning as this kind of data was
          very rapidly changing and are sent at a very fast rate which means
          that validating a packet doesn't really matter if the next packet
          arrives before the validation of the previous one is done.
        </li>
        <h4 className="text-2xl font-bold">Client-side</h4>
        <div
          className={`grid gap-4`}
        >
          <li>
            Within the client side code, we tried to have simple but effective
            handling of networking. Core networking functionality is
            encapsulated in a Network class which is responsible for sending
            relevant data to the server and any responses or messages sent by
            the server are handle in a ClientHandlePackets class.
            <strong>Figure 6</strong> illustrates this relationship between the
            core game and the network. Similar to the server, low-level
            networking is managed via the .NET framework which handles the
            implementation of the TCP and UDP protocols. We aimed to make any
            server communication on the client side to be as simple as possible,
            as shown by the simplicity of the diagram. This allowed to develop
            code that could be incremental in which any data which we wanted to
            communicate client side could be handled through simple functions
            that send data in an easy to replicate format.
          </li>
          <div className="mx-auto">
            <Figure
              img={images[6]}
              alt="figure6"
              caption="Figure 6: Client side UML-style diagram"
            />
          </div>
        </div>
        <h4 className="text-2xl font-bold">Deploying</h4>
        <li>
          We have deployed our server in Amazon Web Services (AWS) and have at
          one instance managed to play with 40 players at the same time in a
          single game instance with no communication lag and smooth player and
          bullet movement.
        </li>
      </section>

      <section className="w-full max-w-screen-lg p-4 mx-auto space-y-4 text-white">
        <h1 className="text-2xl font-bold">Personal Contribution</h1>
        <hr className="border-b border-lime-300" />
        <p>
          Having previous experience and knowledge with Unity, I became the Lead
          Programmer. Initially, prior to the creation of the three development
          teams, I started working with some basic player movement and bullet
          firing. This was appreciated by the other members of the team and we
          decidedto make it our base and build every new feature on top of that.
          Therefore I set up that Unity projectin git and prepared the necessary
          gitignore and gitconfig files.
        </p>
        <p>
          First iterations of Photon Network integration into the game were my
          attempts, in addition to helping other members of the Server-side
          development team how to use it.
        </p>
        <p>
          Then as our attempts at Photon Network did not work out, I initiated
          the fully custom server development, while I programmed the core
          server and at the same time worked with other member helping them
          learn the framework and follow my code, I then had to leave the server
          development team and help out the Gameplay team.
        </p>
        <p>
          Working on the chain between the players, the Gameplay team had some
          trouble conceptualizing it and bringing it to life. I then attempted
          to create a small example of what my idea of the chain would look like
          and after some coding, I managed to create the basic prototype. I
          explained and worked with the Gameplay team to help them understand
          and continue the development of the chain but more difficulties
          presented and therefore I decided to finish the chain implementation
          my self while I let the Gameplay teamwork on the rest of the Gameplay
          features. In addition, I also worked on the task of implementing a
          revival system. It needed a parallel development of both server and
          client-side features, which me having worked on both, I could complete
          the task.
        </p>
        <p>
          I also worked a very minimal amount with the Graphics team, I help add
          some small touches around the game that made the experience feel more
          alive, such as particles on the bullet explode and a trail behind
          them, or even small sound effects together with the background music
          (which was created by another team member).
        </p>
        <div
          className={`grid gap-4 justify-center sm:grid-cols-2 ${styles.projectDetails}`}
        >
          <li>
            As a lead programmer of the project, I decided very early on to use
            Trello boards as a means of keeping track of progress and pending
            tasks. In the beginning, I create a single board page and assigned
            different people tasks. This method fell apart very quickly and it
            was then that I realized that I needed a clear separation between
            people's tasks and a clear way of communicating their end goals.
            This was when I introduced the three-team strategy of Gameplay,
            Server, and Graphics. This strategy improved the team's efficiency
            and with three separate Trello boards, tasks were clearer and were
            completed faster.
          </li>
          <div className="mx-auto">
            <Figure
              img={images[7]}
              alt="figure7"
              caption="Figure 7: Trello boards organization"
            />
          </div>
        </div>
      </section>

      <section className="w-full max-w-screen-lg p-4 mx-auto space-y-4 text-white">
        <h1 className="text-2xl font-bold">Personal Reflection</h1>
        <hr className="border-b border-lime-300" />
        <p>
          One thing I would definitely do better in the future would be to
          integrate the work between the teams consistently and frequently. We
          sometimes took even a whole month before merging everything together
          and having a workable product which meant that the merging was usually
          difficult and time-consuming. Another important lesson I learned for
          managing big projects is to put deadlines and clear goals. Having
          deadlines pushes people in putting work into the project and achieving
          tasks much faster and if the goals set are clear then the members will
          have very little trouble in implementing them. This project was a
          massive undertaking that improved my knowledge of game design, coding,
          server infrastructure, and architecture and also how people and teams
          work within bigger projects.
        </p>
      </section>
      <Footer />
    </div>
  );
}

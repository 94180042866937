import React from "react";
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

export default function GalleryCarousel({ images = [] }) {
  return (
    <Swiper
      // install Swiper modulesc
      className="select-none"
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={50}
      slidesPerView={3}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
    >
      {images.map((img, i) => (
        <SwiperSlide key={i}>
          <img src={img} alt={i} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

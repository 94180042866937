import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./ProjectCard.module.css";

export default function ProjectCard({ proj }) {
  const [hovered, setHovered] = useState(false);
  return (
    <div
      data-aos="fade-up"
      className={`${styles.projectCard} shadow-lg shadow-black transition duration-500 transform hover:scale-105`}
      style={{
        backgroundImage: `url(${proj.img})`,
        ...proj.style,
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div
        className={`absolute top-full w-full transition duration-500 overflow-hidden bg-gradient-to-t from-black to-transparent bg-opacity-50 ${
          !hovered ? styles.projectDesc : styles["projectDesc-hovered"]
        }`}
      >
        <h5 className="m-4 text-lg font-bold">{proj.title}</h5>
        <hr className="mr-4 border-b-4 rounded-r-md border-lime-400" />
        <p className="m-4">{proj.description}</p>
        <Link
          to={proj.link || "#"}
          className="block p-2 m-4 font-semibold transition duration-500 bg-transparent border rounded-md w-max text-lime-300 border-lime-300 hover:bg-lime-300 hover:text-black"
        >
          Learn more
        </Link>
      </div>
    </div>
  );
}

import React from "react";
import { FaArrowLeft, FaGithub, FaCode } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import usePushTransition from "../Hooks/usePushTransition";
import ResponsiveNav from "./Common/ResponsiveNav";

export default function ProjectNavBar({ title, github, navigation = [] }) {
  const [ref, onHover] = usePushTransition();
  const navigate = useNavigate();
  return (
    <nav className="sticky top-0 z-20 w-full text-white bg-zinc-800">
      <div className="flex items-center justify-between w-full max-w-screen-xl p-4 mx-auto">
        <div className="flex items-center space-x-4">
          <button
            onClick={() => navigate("../")}
            className="p-2 border-r border-white"
          >
            <FaArrowLeft size="1rem" />
          </button>
          <a href="#top" className="text-2xl font-bold whitespace-nowrap">{title}</a>
        </div>
        <ResponsiveNav convertWidth={768}>
          {({ dropdown, closeMenu }) => (
            <>
              {github && !dropdown && (
                <a
                  href={github}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center space-x-2 hover:text-lime-300"
                  onClick={closeMenu}
                >
                  <span className="text-lg font-semibold">GitHub:</span>
                  <FaGithub size="2rem" />
                </a>
              )}
              <div
                ref={!dropdown ? ref : null}
                className={`flex mr-2 text-xl font-semibold text-center text-white
                ${
                  dropdown
                    ? "flex-col space-y-8 items-start p-8"
                    : "space-x-8 items-center"
                } `}
              >
                {navigation.map((link) => (
                  <a
                    key={link.text}
                    href={link.location}
                    onMouseOver={onHover}
                    className="transition duration-500 origin-top cursor-pointer"
                    onClick={closeMenu}
                  >
                    {link.text}
                  </a>
                ))}
                {github && dropdown && (
                  <a
                    href={github}
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center space-x-2 hover:text-lime-300"
                    onClick={closeMenu}
                  >
                    <span className="text-lg font-semibold">GitHub:</span>
                    <FaGithub size="2rem" />
                  </a>
                )}
              </div>
            </>
          )}
        </ResponsiveNav>
      </div>
    </nav>
  );
}

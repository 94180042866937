import React from "react";
import Figure from "../Components/Common/Figure";
import GalleryCarousel from "../Components/Common/GalleryCarousel";
import ProjectNavBar from "../Components/ProjectNavBar";
import { FaGithub } from "react-icons/fa";
import styles from "./Pages.module.css";
import Footer from "../Components/Footer";
import Helmet from "react-helmet";

const images = [
  "/images/computer_graphics/ray_tracer_1.png",
  "/images/computer_graphics/ray_tracer_2.png",
  "/images/computer_graphics/ray_tracer_4.png",
  "/images/computer_graphics/ray_tracer_3.png",
  "/images/computer_graphics/anti_aliasing_lvl4.png",
  "/images/computer_graphics/ray_tracer_final.png",
  "/images/computer_graphics/rasterizer_wireframe.png",
  "/images/computer_graphics/rasterizer_interpolation.png",
  "/images/computer_graphics/rasterizer_depth_buffer.png",
  "/images/computer_graphics/rasterizer_illumination.png",
  "/images/computer_graphics/rasterizer_clipping.png",
  "/images/computer_graphics.png",
];

const navLinks = [
  { text: "Raytracer", location: "#raytracer" },
  { text: "Rasterizer", location: "#rasterizer" },
];

export default function ComputerGraphics() {
  return (
    <div className="bg-zinc-900">
      <Helmet>
        <title>Themis - Computer Graphics</title>
      </Helmet>
      <ProjectNavBar title="Computer Graphics" navigation={navLinks} />

      <section className="w-full max-w-screen-lg p-4 mx-auto space-y-2 text-white">
        <h1 className="text-2xl font-bold">Introduction</h1>
        <p>
          Both Raytracer and Rasterizer are build in Visual Studio using C++
          with the OpenGL mathematics (GLM) library and SDL2. The first step for
          both of them was to render correctly the famous Cornell Box and
          afterwards there are some extra additions for each method.
        </p>
      </section>

      <section className="w-full max-w-screen-lg p-4 mx-auto space-y-2 text-white">
        <h1 className="text-2xl font-bold">Gallery</h1>
        <GalleryCarousel images={images} />
      </section>

      <section
        className="w-full max-w-screen-lg p-4 mx-auto space-y-2 text-white"
        style={{scrollMarginTop: "80px"}}
        id="raytracer"
      >
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold">Raytracer</h1>
          <a
            href="https://github.com/ThemisP/raytracer"
            target="_blank"
            rel="noreferrer"
            className="flex items-center space-x-2 hover:text-lime-300"
          >
            <span className="text-lg font-semibold">GitHub:</span>
            <FaGithub size="2rem" />
          </a>
        </div>
        <hr className="border-b border-lime-300" />
        <p>
          Raytracing is a method which draws images of 3D scenes by tracing the
          light rays reaching the simulated camera.
        </p>
        <div
          className={`grid gap-4 justify-center sm:grid-cols-2 ${styles.projectDetails}`}
        >
          <li className="sm:col-span-2">
            The first step is to realise how to represent objects as triangles
            and triangles as 3 vertices with a normal and a colour. This is a
            core concept of how items are stored and represented which is not
            only related to Raytracing but in many major 3D software the idea of
            how objects are stored is similarly. (Although the representation
            with only vertices, normal and colour is overly simplistic)
          </li>
          <li>
            The core concept of Raytracing is how light bounces around in space.
            Raytracing is an attempt at simulating that behavior. This is done
            by shooting rays from the camera towards the objects in the scene
            and calculating the intersection of that ray with the objects,
            specifically the closest intersection. By sending a ray from each
            pixel of the screen through the virtual camera you can get the
            colour value of the closest intersection for each ray which results
            in the image shown in <strong>Figure 1.</strong>
          </li>
          <div className="mx-auto">
            <Figure
              img={images[0]}
              alt="figure1"
              caption="Figure 1: Cornell Box"
            />
          </div>
          <li>
            Direct Illumination: The core step of calculating the intersection
            of the rays results in an image where the objects have exactly the
            same colour everywhere without any depth and the feeling of 3D is
            not present in the image. For more realism light is added,
            specifically an omni light which spreads light equally in all
            directions from a single point in space. The light is represented by
            its position, and its power in each color component. If a surface is
            further away from the light source it receives less light.
            Calculating just one "bounce" of light in the scene and not
            considering colour produces the image shown in Figure 2.
          </li>
          <div className="mx-auto">
            <Figure
              img={images[1]}
              alt="figure2"
              caption="Figure 2: Direct Illumination"
            />
          </div>
          <li>
            <strong>Indirect Illumination:</strong> Just calculating direct
            illumination makes the image have very dark spots at some points
            which is not realistic. In the real world some light is absorbed by
            the material but some of it is reflected. To simplify indirect
            illumination we assume that all objects in the scene are made of the
            same material and therefore reflect the same amount of light. The
            resulting light intensity is shown in <strong>Figure 3.</strong>
          </li>
          <div className="mx-auto">
            <Figure
              img={images[2]}
              alt="figure3"
              caption="Figure 3: Indirect Illumination"
            />
          </div>
          <li>
            <strong>Direct Shadows: Figure 3</strong> also shows the effects of
            direct shadows, meaning that if another surface intersects the ray
            from the light source to the current surface then that surface does
            not receive direct illumination. This produces a simple sharp
            shadow. <strong>Figure 4</strong>
            shows the end result when you mix the colours of the surfaces with
            direct illumination, direct shadows and indirect illumination.
          </li>
          <div className="mx-auto">
            <Figure
              img={images[3]}
              alt="figure4"
              caption="Figure 4: Direct Shadows"
            />
          </div>
          <li>
            <strong>Anti Aliasing:</strong> At the edges of the objects there
            are some visible jagged lines which make the image look low
            resolution or pixelated, they are especially visible on the red
            cube. This effect is caused because we draw pixel by pixel without
            transitioning from object to object making the pixels either the
            colour of the cube or the colour of the background for example.
            Anti-aliasing is a method for resolving that problem. In Raytracing
            anti-aliasing works by using multiple rays for each pixel instead of
            one and averaging their resulting values. A simple explanation for
            it is trying to render a higher resolution image and downsizing it.{" "}
            <strong>Figure 4 and 5</strong> show the difference between the
            image without anti-aliasing and the image with level 4 (16 rays for
            each pixel) anti-aliasing.
          </li>
          <div className="mx-auto">
            <Figure
              img={images[4]}
              alt="figure5"
              caption="Figure 5: Anti-aliasing"
            />
          </div>
          <li>
            Experimenting further with the Raytracer, I implemented Reflection,
            Refraction and combined them through the Fresnel Equation for
            transparent objects. Raytracing is a very good simulation of the
            real world which produces realistic and amazing results for computer
            graphics. It is computationally expensive though and even though
            optimizations such as Cramer's rule, render even this simple scene
            with anti-aliasing and reflection/refraction and transparency is
            very intense for a computer.
          </li>
          <div className="mx-auto">
            <Figure
              img={images[5]}
              alt="figure6"
              caption="Figure 6: Reflection & Refraction"
            />
          </div>
        </div>
      </section>

      <section
        className="w-full max-w-screen-lg p-4 mx-auto space-y-2 text-white"
        style={{scrollMarginTop: "80px"}}
        id="rasterizer"
      >
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold">Rasterizer</h1>
          <a
            href="https://github.com/ThemisP/Rasterizer"
            target="_blank"
            rel="noreferrer"
            className="flex items-center space-x-2 hover:text-lime-300"
          >
            <span className="text-lg font-semibold">GitHub:</span>
            <FaGithub size="2rem" />
          </a>
        </div>
        <hr className="border-b border-lime-300" />
        <p>
          Although Raytracing is simple and can simulate all kinds of phenomena,
          its speed is its disadvantage and therefore it is typically not used
          for real-time visualization. For those scenarios another method is
          used called Rasterization. Rasterization is usually faster than
          Raytracing but it cannot easily and effectively simulate all
          illumination phenomena.
        </p>
        <div
          className={`grid gap-4 justify-center sm:grid-cols-2 ${styles.projectDetails}`}
        >
          <li className="sm:col-span-2">
            The first steps are the same as Raytracing, you have to represent
            objects using triangles, vertices and edges.
          </li>
          <li>
            The concept of Rasterization is to get a 3D image convert it into a
            series of pixels dots or lines which when displayed together, create
            the view of the image with shapes. Basically getting a single
            perspective of the 3D image making it into 2D as a flat picture and
            then drawing on top correctly to give the illusion of depth. By
            calculating the projection of the 3 dimensional vertices onto a 2
            dimensional plane that we want to view (our screen) we can plot the
            points and join them to create a wireframe of the image as shown in{" "}
            <strong>Figure 7</strong>.
          </li>
          <div className="mx-auto">
            <Figure
              img={images[6]}
              alt="figure7"
              caption="Figure 7: Wireframe"
            />
          </div>
          <li>
            <strong>Interpolation:</strong> In Rasterization interpolating
            between two points to find the pixels necessary to draw the image is
            very crucial. To draw inside a triangle correctly, first you need to
            interpolate between the vertices and find the bounding left and
            right pixels for each y-level, then you fill the triangle by drawing
            lines for each y-level reaching between the left the right most
            pixel at that row. The process is to identify the outline of the
            triangle and then row by row draw the required colour.{" "}
            <strong>Figure 8</strong> shows the result of that process.
          </li>
          <div className="mx-auto">
            <Figure
              img={images[7]}
              alt="figure8"
              caption="Figure 8: Interpolation"
            />
          </div>
          <li>
            <strong>Depth Buffer:</strong> There is an apparent problem with the
            image in <strong>Figure 8</strong>, the blue box is drawn on top of
            the red box when it should be the opposite. This issue is emerged
            because the triangles are drawn in order without any consideration
            for the depth of each triangle and therefore they overwrite the
            pixel colour. The solution for this issue is to introduce a depth
            buffer where each pixel drawn on screen keeps some information about
            its depth and then if another triangle tries to draw on that pixel
            it will first check if it is in front of the previously drawn pixel
            and only then it will overwrite it. However the information stored
            for depth (z value) is not depth it self, its the inverse of depth
            (1/z value). The reason for storing the inverse of depth is because
            in 3D the depth is linearly interpolated across two vertices but in
            its projection in 2D, depth cannot be linearly interpolated, instead
            the inverse of depth will vary linearly and therefore it makes the
            interpolating calculations much easier. <strong>Figure 9</strong>{" "}
            shows the results after introducing the depth buffer.
          </li>
          <div className="mx-auto">
            <Figure
              img={images[8]}
              alt="figure9"
              caption="Figure 9: Depth Buffer"
            />
          </div>
          <li>
            <strong>Per Pixel illumination:</strong> to produce illumination
            results for each pixel, you have to store the 3D position for every
            pixel through interpolation and then use that 3D position to
            calculate the correct colour to be drawn for that pixel. The
            perspective correct interpolated 3D values need to be calculated
            otherwise the lighting on the image will look odd.{" "}
            <strong>Figure 10</strong> shows the effects of the per pixel
            illumination.
          </li>
          <div className="mx-auto">
            <Figure
              img={images[9]}
              alt="figure10"
              caption="Figure 10: Pixel Illumination"
            />
          </div>
          <li>
            <strong>Clipping:</strong> Referencing back to the depth buffer, we
            said that the inverse of the depth is stored in there but what
            happens if the depth is 0? Division by zero is a problem and in fact
            the closer a triangle is to the camera th bigger a number is thus
            making the division and the storage of the inverse of depth a
            performance issue. Other issues is that even if objects are behind
            or not located in the field of view of the screen, are processed.
            Clipping is a major performance optimization which allows for
            objects only found in the view space of the camera to be visible
            which is the left,right, top and bottom clipping but also front and
            back clipping allows for things that are pretty far away to not be
            rendered or items behind the camera. <strong>Figure 11</strong>{" "}
            shows front clipping where the red box is sliced because it is too
            close to the camera. Clipping in this example slices the red box and
            more smaller triangles are created to account for the shape change
            in the picture. Clipping is usually performed in{" "}
            <strong>Homogeneous coordinates</strong> as it is an easier method
            to check if a point lies inside or outside the viewing volume.
          </li>
          <div className="mx-auto">
            <Figure
              img={images[10]}
              alt="figure11"
              caption="Figure 11: Clipping"
            />
          </div>
          <li className="sm:col-span-2">
            <strong>Procedural Generation and Perlin Noise:</strong> Lastly I
            got involved with some simple procedural generation techniques. I
            wanted to create a terrain like object that would feel like
            mountains and valleys, completely generated by a function. Kin
            Perlin noise is in simple terms a mathematical noise function that
            when viewed from a distance it seems like the values are randomly
            generated but when you look at a value you can see that there is a
            correlation between it and its neighboring values. This property of
            the function creates a smooth transition between values but also a
            pseudo-random pattern in general. To create the terrain, I started
            by generating a flat grid of vertices and then joining them in
            triangles. Using a 2 dimensional version of the Perlin noise, I
            determined each vertex height (y-value) from its x and z coordinate
            and matched it to a point in Perlin noise function. Playing around
            with the scale of the Perlin noise in order to find a configuration
            that seemed good. To finish off the "illusion" of a terrain I added
            a colour pallette so that low height levels would be blue, then
            cyan, then green then white with a blending option between the
            colours for a smooth transition. The end result is shown in{" "}
            <strong>Figure 12</strong>.
          </li>

          <div className="mx-auto sm:col-span-2">
            <Figure
              img={images[11]}
              alt="figure12"
              caption="Figure 12: Procedural Generation"
            />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

import React from "react";
import "react-circular-progressbar/dist/styles.css";
import useScreenVisible from "../../Hooks/useScreenVisible";

export default function Skill({ icon, text, progress }) {
  const [ref, isVisible] = useScreenVisible();
  return (
    <div className="w-full" ref={ref}>
      <div className="grid items-center gap-2 my-1 text-xl font-semibold" style={{gridTemplateColumns: "2rem 1fr 3rem"}}>
        {icon} 
        <span>{text}</span>
        <p className="text-sm font-normal text-right">{progress}%</p>
      </div>
      <div className="w-full h-2 rounded-full shadow-inner bg-zinc-800">
        <div
          className="h-2 rounded-full bg-gradient-to-br from-lime-400 to-lime-300"
          style={{
            width: isVisible ? `${progress}%` : "0",
            transition: "width 500ms ease-in-out",
          }}
        />
      </div>
    </div>
  );
}
